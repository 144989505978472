import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Navbar from "../Components/Navbar";
import Projects from "../Components/Projects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

function HomePage() {
  const typedRef = useRef(null);
  const projectsRef = useRef(null);

  const skills = [
    { name: "JavaScript", level: 95 },
    { name: "React", level: 90 },
    { name: "Python", level: 100 },
    { name: "Java", level: 95 },
  ];

  useEffect(() => {
    const initializeTyped = () => {
      if (typedRef.current) {
        const options = {
          strings: [
            "Hi, I'm <span class='text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-pink-500'>Kittu Katakam</span>",
          ],
          typeSpeed: 50,
          backSpeed: 50,
          loop: false,
          showCursor: false,
        };
        new Typed(typedRef.current, options);
      } else {
        setTimeout(initializeTyped, 100);
      }
    };
    initializeTyped();
  }, []);

  const handleSeeMyWorkClick = () => {
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const socialLinks = {
    linkedin: "https://www.linkedin.com/in/kittu-katakam-179b4b171/",
    discord: "https://discord.com/users/your-discord-id",
    email: "mailto:kittu.krish@gmail.com",
    github: "https://github.com/RKKatakam",
  };

  return (

    <div className="bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white min-h-screen font-sans">

      {/* Navbar */}
      <Navbar />


      {/* Hero Section */}
      <section id="hero" className="container mx-auto px-6 py-24 flex flex-col md:flex-row items-center justify-between">
        {/* Hero Image */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="md:w-1/2 mb-12 md:mb-0"
        >
          <img
            src="Kittu.png"
            alt="Kittu Katakam"
            className="rounded-full shadow-2xl w-full md:max-w-md"
          />
        </motion.div>

        {/* Hero Text */}
        <div className="md:w-1/2 text-center md:text-left">
          <h1
            className="text-5xl md:text-6xl font-extrabold mb-6"
            ref={typedRef}
          >
            {/* Typed.js will inject the text here */}
          </h1>
          <p className="text-xl md:text-2xl mb-8 leading-relaxed text-gray-300">
            Exploring the intersection of AI, design, and human-computer
            interaction.
          </p>
          <div className="flex flex-col md:flex-row items-center justify-center md:justify-start">
            <motion.a
              whileHover={{ scale: 1.05 }}
              href="#projects"
              className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-3 px-6 rounded-full mb-4 md:mb-0 md:mr-4 transition duration-300"
              onClick={handleSeeMyWorkClick}
            >
              See My Work
            </motion.a>
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="flex space-x-4"
            >
              <li>
                <a href={socialLinks.github}>
                  <FontAwesomeIcon
                    icon={faGithub}
                    className="w-6 h-6 hover:text-indigo-500 transition duration-300"
                  />
                </a>
              </li>
              <li>
                <a
                  href={socialLinks.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="w-6 h-6 hover:text-indigo-500 transition duration-300"
                  />
                </a>
              </li>
              <li>
                <a
                  href={socialLinks.discord}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faDiscord}
                    className="w-6 h-6 hover:text-indigo-500 transition duration-300"
                  />
                </a>
              </li>
              <li>
                <a href={socialLinks.email}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="w-6 h-6 hover:text-indigo-500 transition duration-300"
                  />
                </a>
              </li>
            </motion.ul>
          </div>
        </div>
      </section>

      {/* Skills Section */}
      <section id="skills" className="container mx-auto px-6 py-12">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
          My Skills
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {skills.map((skill, index) => (
            <SkillCard key={index} skill={skill} delay={index * 0.2} />
          ))}
        </div>
      </section>

      {/* Projects Section */}
      <div id="projects" ref={projectsRef}>
        <Projects />
      </div>

      {/* Footer */}
      <footer className="bg-gray-800 text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; {new Date().getFullYear()} Kittu Katakam. All rights reserved.</p>
          <div className="flex justify-center space-x-4 mt-4">
            <a href={socialLinks.github}>
              <FontAwesomeIcon
                icon={faGithub}
                className="w-5 h-5 hover:text-indigo-500 transition duration-300"
              />
            </a>
            <a
              href={socialLinks.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className="w-5 h-5 hover:text-indigo-500 transition duration-300"
              />
            </a>
            <a
              href={socialLinks.discord}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faDiscord}
                className="w-5 h-5 hover:text-indigo-500 transition duration-300"
              />
            </a>
            <a href={socialLinks.email}>
              <FontAwesomeIcon
                icon={faEnvelope}
                className="w-5 h-5 hover:text-indigo-500 transition duration-300"
              />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

const SkillCard = ({ skill, delay }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
controls.start("animate");
    }
  }, [controls, inView]);

  const circleVariants = {
    initial: { strokeDashoffset: Math.PI * 2 * 52 },
    animate: {
      strokeDashoffset:
        Math.PI * 2 * 52 * ((100 - skill.level) / 100),
      transition: { duration: 1.5, ease: "easeOut", delay: delay },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      variants={{
        animate: { opacity: 1, y: 0, transition: { delay: delay } },
      }}
      className="bg-gray-800 rounded-lg p-6 hover:shadow-lg transition-shadow duration-300 transform hover:scale-105"
    >
      <h3 className="text-xl font-semibold mb-4 text-center">
        {skill.name}
      </h3>
      {/* Animated Circular Progress Bar */}
      <div className="flex items-center justify-center relative">
        <svg className="w-24 h-24" viewBox="0 0 120 120">
          <circle
            className="text-gray-700"
            strokeWidth="10"
            stroke="currentColor"
            fill="transparent"
            r="52"
            cx="60"
            cy="60"
          />
          <motion.circle
            className="text-indigo-500"
            strokeWidth="10"
            stroke="currentColor"
            fill="transparent"
            r="52"
            cx="60"
            cy="60"
            strokeLinecap="round"
            strokeDasharray={Math.PI * 2 * 52}
            variants={circleVariants}
            initial="initial"
            animate="animate"
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-xl font-bold">{skill.level}%</span>
        </div>
      </div>
    </motion.div>
  );
}

export default HomePage;