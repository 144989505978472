import React, { useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const projects = [
  {
    image: "TicAI.jpg",
    title: 'Tic-Tac-Toe AI with Monte Carlo Tree Search',
    description:
      'Developed a Tic-Tac-Toe AI in C++ using the Monte Carlo Tree Search algorithm, achieving ~70% agreement with the minimax algorithm for optimal moves. Implemented tree traversal, random simulations, and backpropagation to optimize decision-making.',
    link: 'https://github.com/RKKatakam/MCTS',
    
  },
  {
    image: "PipelineAi.jpg",
    title: 'Automated Image-QA Data Pipeline',
    description:
      'Engineered a Python-based data pipeline to generate image-question-answer pairs for model training. Automated image extraction, leveraged Google Cloud Storage and Firestore for data management, and implemented Cloud Functions for seamless pipeline orchestration.',
    link: 'https://github.com/RKKatakam/Synthetic-Data-Generation',
  },
  {
    image: "SnakeAi.jpg",
    title: 'Genetic Algorithm for Snake Game AI',
    description:
      'Developed a Java-based AI for the classic Snake game using genetic algorithms, focusing on selection and mutation processes. This project was adopted by an AP CS teacher to introduce machine learning concepts due to its simplicity and effectiveness.',
    link: 'https://github.com/RKKatakam/SnakeAI',
  },
];

const Projects = () => {
  return (
    <section className="py-24">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-bold mb-16 text-center text-indigo-400">
          My Projects
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} delay={index * 0.2} />
          ))}
        </div>
      </div>
    </section>
  );
};

const ProjectCard = ({ project, delay }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.2,
    });
  
    useEffect(() => {
      if (inView) {
        controls.start('animate');
      }
    }, [controls, inView]);
  
    return (
      <motion.div
        ref={ref}
        initial="initial"
        animate={controls}
        variants={{
          initial: { opacity: 0, y: 50 },
          animate: {
            opacity: 1,
            y: 0,
            transition: { type: 'spring', stiffness: 100, delay: delay },
          },
        }}
        className="relative bg-gray-800 rounded-lg shadow-lg overflow-hidden transform transition-transform hover:-translate-y-2"
        style={{ zIndex: 1 }}
      >
        <img
          src={project.image}
          alt={project.title}
          className="w-full h-48 object-cover"
        />
        <div className="p-6">
          <a href={project.link} target="_blank" rel="noopener noreferrer">
            <h3 className="text-xl font-bold mb-2 text-indigo-400 hover:text-pink-400 transition-colors duration-300">
              {project.title}
            </h3>
          </a>
          <p className="text-gray-300 text-base mb-4">{project.description}</p>
          <a
            href={project.link}
            className="text-blue-500 hover:underline font-medium"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Project
          </a>
        </div>
      </motion.div>
    );
  };

export default Projects;