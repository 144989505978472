// src/Components/Navbar.jsx
import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed w-full z-20 top-0 left-0 bg-gray-900 bg-opacity-80 backdrop-filter backdrop-blur-lg">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-3">
        <a href="/" className="flex items-center">
          {/* Add custom logo image */}
        </a>
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="inline-flex items-center p-2 text-sm text-gray-400 rounded-lg md:hidden hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={isOpen}
        >
          <span className="sr-only">Open main menu</span>
          {!isOpen ? (
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M3 5h14M3 10h14M3 15h14" />
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M6 6L14 14M14 6L6 14" />
            </svg>
          )}
        </button>
        <div
          className={`w-full md:block md:w-auto ${
            isOpen ? 'block' : 'hidden'
          }`}
          id="navbar-default"
        >
          <ul className="flex flex-col p-4 mt-4 bg-gray-800 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:bg-transparent">
            <li>
              <ScrollLink
                to="hero"
                smooth={true}
                duration={500}
                offset={-70}
                className="block py-2 pr-4 pl-3 text-white hover:text-indigo-400 md:p-0 cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Home
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="skills"
                smooth={true}
                duration={500}
                offset={-70}
                className="block py-2 pr-4 pl-3 text-white hover:text-indigo-400 md:p-0 cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Skills
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="projects"
                smooth={true}
                duration={500}
                offset={-70}
                className="block py-2 pr-4 pl-3 text-white hover:text-indigo-400 md:p-0 cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Projects
              </ScrollLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;